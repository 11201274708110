<template>
  <div>
    <Modal :options="{width: '40vw'}" @close="$emit('close')">
      <div class="title">{{ !opcion.nombre ? 'Nueva' : 'Editar' }} opción</div>
      <div class="body">
        <div class="row form-group">
          <label for="nombre" class="col-form-label col-sm-3">Nombre</label>
          <div class="col-sm-9"><input v-model="nueva_opcion.nombre" type="text" name="nombre" id="nombre" class="form-control"></div>
        </div>
        <div class="row form-group">
          <label for="valor" class="col-form-label col-sm-3">Valor</label>
          <div class="col-sm-9"><input v-model="nueva_opcion.valor" type="text" name="valor" id="valor" class="form-control"></div>
        </div>
        <div class="row form-group">
          <label for="estatus" class="col-form-label col-sm-3">Estatus</label>
          <div class="col-sm-9">
            <select v-model="nueva_opcion.estatus" name="estatus" id="estatus" class="form-control">
              <option value="1">Activo</option>
              <option value="0">Inactivo</option>
            </select>
          </div>
        </div>
      </div>
      <div class="footer">
        <div class="row">
          <div class="col-sm-12 text-right">
            <button class="btn btn-primary mr-2" @click="agregar_opcion">Guardar</button>
            <button class="btn btn-danger" @click="$emit('close')">Cancelar</button>
          </div>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script type="text/javascript">
  import Modal from '@/components/Modal'

  export default {
    components: {
      Modal
    }
    ,props: {
      opcion: {
        nombre: null
        ,valor: null
        ,estatus: 1
      }
    }
    ,data() {
      return {
        nueva_opcion: {
          nombre: null
          ,valor: null
          ,estatus: 1
        }
      }
    }
    ,mounted() {
      this.nueva_opcion = this.opcion;
    }
    ,methods: {
      agregar_opcion() {
        if (!this.nueva_opcion.nombre)
          return this.$helper.showMessage('Error','Debes definir el nombre','error','alert');
          
        if (!this.nueva_opcion.valor)
          return this.$helper.showMessage('Error','Debes definir el valor','error','alert');

        this.$emit('add',this.nueva_opcion);
      }
    }
  }
</script>